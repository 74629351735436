<template>
  <v-text-field
    label="Search By Image Hash"
    outlined
    v-model="search"
    @input="$emit('search', search)"
    single-line
  ></v-text-field>
</template>

<script>
export default {
  name: 'ImageHashSearch',
  data() {
    return {
      search: '',
    };
  },
};
</script>
