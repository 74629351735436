<template>
  <div>
    <v-text-field
      v-if="isTextField"
      :label="label"
      ref="copyText"
      outlined
      readonly
      dense
      :append-icon="appendingIcon"
      @click:append="copyToClipboard()"
      :disabled="copyText === ''"
      :value="copyText"
    ></v-text-field>
    <v-textarea
      v-else
      outlined
      ref="copyText"
      :label="label"
      :value="copyText"
      :append-icon="appendingIcon"
      readonly
      :disabled="copyText === ''"
      @click:append="copyToClipboard()"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'ClipboardCopy',
  props: {
    label: {
      type: String,
      default: 'label',
    },
    copyText: {
      type: String,
      default: '',
    },
    isTextField: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      appendingIcon: 'mdi-content-copy',
    };
  },
  methods: {
    revertCopyIcon() {
      setTimeout(() => {
        this.appendingIcon = 'mdi-content-copy';
        this.$refs.copyText.blur();
      }, 1000);
    },
    async copyToClipboard() {
      try {
        this.$refs.copyText.focus();
        this.appendingIcon = 'mdi-check';
        await navigator.clipboard.writeText(this.copyText);
        this.$emit('success');
        this.revertCopyIcon();
      } catch (err) {
        this.appendingIcon = 'mdi-window-close';
        this.$emit('error');
        this.revertCopyIcon();
      }
    },
  },
};
</script>
