<template>
  <v-container fluid v-if="loadingImages || loadingFolders || loadingLabels">
    <Loading />
  </v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <EpriMap
          v-if="companyHas('poleByFolders')"
          :images="pImages.withLocation"
          :folders="folders.withLocation"
          :key="pImages.withLocation.length"
        />
        <StructureMap v-else-if="companyHas('structure_map')"
                 :images="pImages.withLocation"
                 :folders="folders.withLocation"
                 :key="pImages.withLocation.length"/>
        <GeneralMap
          v-else
          :folderImages="folderImages.all"
          :images="mapImages.withLocation"
          :key="severity"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="7" lg="7" xl="7">
        <CarouselController
          :images="folderImages.all"
          type="ai"
          :cid="currentCompany.cid"
          :currentImage="currentImage"
        />
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="5" xl="5">
        <v-row>
          <v-col>
            <div id="structure-notes">
              <v-btn color="primary" :depressed="!showNote" @click="toggleNote(false)" class="mr-1">
                Structure Detail
              </v-btn>
              <v-btn color="primary" :depressed="showNote" @click="toggleNote(true)">
                Structure Note
              </v-btn>
            </div>
            <div id="refresh-btn" v-if="companyHas('refresh_btn')">
              <ImageRefreshButton />
            </div>
          </v-col>
        </v-row>
        <div id="details">
          <ImageDetails type="ai" :index="currentIndex" v-if="!showNote"/>
          <FolderNote v-else/>
        </div>
        <SimpleFileManager :images="folderImages.all" type="ai" :showSeverityFilters="true" />
        <v-container>
          <LabelAbbreviations view="images"/>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import CarouselController from '@components/images/CarouselController.vue';
import GeneralMap from '@components/images/map/GeneralMap.vue';
import EpriMap from '@components/images/map/EpriMap.vue';
import Loading from '@components/common/Loading.vue';
import LabelAbbreviations from '@components/images/LabelAbbreviations.vue';
import SimpleFileManager from '@components/folders/SimpleFileManager.vue';
import FolderNote from '@components/folders/FolderNote.vue';
import ImageDetails from '@components/images/image_details/ImageDetails2.vue';
import ImageRefreshButton from '@components/images/ImageRefreshButton.vue';
import hasLocation from '@methods/locations';
import StructureMap from '@components/images/map/StructureMap.vue';

export default {
  name: 'ProcessedImagesContent',
  props: ['filename'],
  components: {
    StructureMap,
    CarouselController,
    GeneralMap,
    Loading,
    SimpleFileManager,
    ImageDetails,
    EpriMap,
    LabelAbbreviations,
    FolderNote,
    ImageRefreshButton,
  },
  data: () => ({
    currentIndex: 0,
    viewFolder: false,
    viewImage: true,
    showNote: false,
    severity: '',
  }),
  methods: {
    ...mapActions(['setCurrentImage', 'setSelectedImages', 'setLazyLoadPointer']),
    toggleNote(status) {
      this.showNote = status;
    },
    getProcessedImages(images) {
      return images
        .filter((image) => image.processedImageUrl)
        .map((image) => {
          const currImage = { ...image };

          const { length } = currImage.process_tracking;

          if (length === 0) {
            currImage.process_tracking.push({});
          }

          const processedImage = {
            ...currImage,
            filename: currImage.filename,
            location: currImage.location,
            confidence: currImage.process_tracking[length - 1].confidence,
            severity: currImage.process_tracking[length - 1].severity,
            classes: currImage.process_tracking[length - 1].labels,
            regions: currImage.process_tracking[length - 1].regions,
            polygons: currImage.process_tracking[length - 1].polygons,
            notes: (Object.keys(currImage.process_tracking[length - 1]).includes('notes')) ? currImage.process_tracking[length - 1].notes : [],
            url: currImage.compressedProcessUrl,
            date: currImage.date,
            companyId: currImage.companyId,
            project_id: currImage.project_id,
            process_tracking: currImage.process_tracking,
            user_id: currImage.user_id,
            processedImageUrl: currImage.processedImageUrl,
            originalImageUrl: currImage.originalImageUrl,
            folder: currImage.folder,
            time_logs: currImage.time_logs,
          };
          if (Object.keys(currImage).includes('gimbalPitchDegree')) processedImage.gimbalPitchDegree = currImage.gimbalPitchDegree;

          return processedImage;
        });
    },
  },
  computed: {
    ...mapGetters(['allImages', 'currentCompany', 'loadingImages', 'currentImage', 'currentFolder', 'loadingFolders', 'companyHas', 'currentSeverityFilters', 'allFolders', 'loadingLabels', 'lazyLoadPointer']),
    pImages() {
      const allProcessedImages = this.getProcessedImages(this.allImages);

      if (window.location.href === 'nfpower.buzzpowerai.com') {
        this.allImages.filter((image) => {
          const { originalImageUrl, processedImageUrl } = image;

          return originalImageUrl.includes('buzz-powerai-cbucket-epri-newfoundland-test')
            || processedImageUrl.includes('buzz-powerai-cbucket-epri-newfoundland-test');
        });
      }

      const noLocation = allProcessedImages.filter((image) => {
        if (!image.location) return true;
        return !Object.keys(image).includes('location')
          || image.location.length < 2
          || (image.location[0] === null && image.location[1] === null);
      });
      const withLocation = allProcessedImages.filter((image) => {
        if (!image.location) return false;
        return (Object.keys(image).includes('location')
          || image.location.length === 2)
          && (image.location[0] !== null && image.location[1] !== null);
      });

      // Filter by current severity filters on each images

      return {
        all: allProcessedImages,
        noLocation,
        withLocation,
      };
    },
    folderImages() {
      const filterPath = (imgs) => imgs.filter((e) => e.folder === this.currentFolder.path);

      const isAllFolder = this.currentFolder.path === '__all__';
      const all = (isAllFolder)
        ? this.pImages.all : filterPath(this.pImages.all);
      const noLocation = (isAllFolder)
        ? this.pImages.noLocation : filterPath(this.pImages.noLocation);
      const withLocation = (isAllFolder)
        ? this.pImages.withLocation : filterPath(this.pImages.withLocation);

      const filterBySeverity = (images) => {
        if (this.currentSeverityFilters.length < 1) return images;
        return images.filter((image) => {
          const current = cloneDeep(image);

          const processedData = current.process_tracking.slice(-1)[0];
          return this.currentSeverityFilters.includes(processedData.severity);
        });
      };

      return {
        all: filterBySeverity(all),
        noLocation: filterBySeverity(noLocation),
        withLocation: filterBySeverity(withLocation),
      };
    },
    folders() {
      if (!this.companyHas('poleByFolders') && !this.companyHas('structure_map')) return [];
      return {
        all: this.allFolders,
        withLocation: this.allFolders.filter((folder) => hasLocation(folder)),
        noLocation: this.allFolders.filter((folder) => !hasLocation(folder)),
      };
    },
    mapImages() {
      if (this.allFolders.length < 1) return this.pImages;
      return this.folderImages;
    },
  },
  async created() {
    const [image] = this.allImages.filter((img) => img.filename === this.filename);
    this.setCurrentImage(image);
  },
  destroyed() {
    this.setLazyLoadPointer(0);
  },
  watch: {
    currentFolder(newFolder, oldFolder) {
      if (this.$route.name !== 'ProcessedImages') return;

      if (this.folderImages.all.length > 0 && newFolder.id !== oldFolder.id) {
        this.setCurrentImage(this.folderImages.all[0]);
      }
      this.setSelectedImages([]);
    },
    currentSeverityFilters(newFilters) {
      if (newFilters.length < 1) this.severity = '';
      else [this.severity] = newFilters;
    },
    lazyLoadPointer(newPointer) {
      const currentImage = this.folderImages.all[newPointer];
      if (currentImage.id !== this.currentImage.id) {
        this.setCurrentImage(currentImage);
      }
    },
  },
};
</script>
