import platformBackendClient from '@backend-clients/platform-backend';

export default {
  all: (companyId, projectId) => platformBackendClient.get(`/api/processed-images/companies/${companyId}/projects/${projectId}`),
  delete: (companyId, projectId, cid, pid, images) => platformBackendClient.delete(
    `/api/processed-images/companies/${companyId}/projects/${projectId}/?cid=${cid}&pid=${pid}`,
    { data: images },
  ),
  getByImageHash: (companyId, projectId, imageHash) => platformBackendClient.get(`/api/processed-images/companies/${companyId}/projects/${projectId}/images/${imageHash}`),
};
