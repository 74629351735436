<template>
  <div>
    <h3>Confidence Range</h3>
    <div style="margin-left: 20px" :key="label"  v-for="label in dashboardFilters.labels">
      <div>
        <div>{{label}}:</div>
        <div style="display: flex;">
          <div style="display: flex; flex-direction: row;align-items: center">
            <div style="margin: 0px 10px">
              From
            </div>
            <v-text-field type="number" step="0.01"
                          :value="dashboardFilters.range[label]?.from"
                          @change="(e)=>confidenceRangeChanged(e,label,'from')"/>
          </div>
          <div style="display: flex; flex-direction: row;align-items: center">
            <div style="margin: 0px 10px">
              To
            </div>
            <v-text-field type="number" step="0.01"  :value="dashboardFilters.range[label]?.to"
                          @change="(value)=>confidenceRangeChanged(value,label,'to')"   />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConfidenceRangeFilter',
  computed: {
    ...mapGetters(['dashboardFilters']),
  },
  methods: {
    confidenceRangeChanged(value, label, type) {
      const confidence = parseFloat(value);
      if (Number.isNaN(confidence) || confidence > 1 || confidence < 0) {
        alert(`${value} is not a valid confidence value`);
        return;
      }
      let labelRange = {};
      if (this.dashboardFilters.range[label]) {
        labelRange = this.dashboardFilters.range[label];
      }
      labelRange[type] = confidence;
      this.dashboardFilters.range[label] = labelRange;
    },
  },
  watch: {
    range: {
      handler(newVal) {
        this.onChange(newVal);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
