<template>
  <div class="dashboard-filters">
      <FilterByFaultTypes />
      <div id="priority-filters" v-if="companyHas('poleByFolders')">
        <FilterByPriority />
      </div>
      <div id="severity-filters" v-else>
        <FilterBySeverity />
      </div>
    <div style="display: flex; flex-direction: row;align-items: center">
      <div style="margin: 0px 10px">
        HITL
      </div>
      <v-switch v-model="HITL" />
    </div>
<!--    confidence  range filter-->
    <ConfidenceRangeFilter v-if="this.dashboardFilters.labels.length > 0" />
    <div style="text-align: right">
      <v-btn @click="fetchData()">
        Apply
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterByFaultTypes from '@components/dashboard/filters/FilterByFaultTypes.vue';
import FilterBySeverity from '@components/dashboard/filters/FilterBySeverity.vue';
import FilterByPriority from '@components/dashboard/filters/FilterByPriority.vue';
import ConfidenceRangeFilter from '@components/dashboard/filters/ConfidenceRangeFilter.vue';
import { getFolderIdsByPathArray } from '@utils/folder';

export default {
  name: 'DashboardFilters',
  props: ['updateMatch'],
  data: () => ({
    HITL: false,
  }),
  components: {
    FilterByFaultTypes,
    FilterBySeverity,
    FilterByPriority,
    ConfidenceRangeFilter,
  },
  computed: {
    ...mapGetters(['dashboardFilters', 'currentCompany', 'currentProject', 'companyHas']),
  },
  methods: {
    ...mapActions(['getDashboardImagesByFilters']),
    fetchData() {
      const { range, ...newVal } = this.dashboardFilters;
      const query = {
        ...newVal,
        structures: getFolderIdsByPathArray(newVal.folders),
      };
      delete query.folders;
      if (this.HITL) {
        query.hitl = this.HITL;
      }

      if (Object.keys(range).length > 0) {
        const confidences = [];
        Object.entries(range).filter(([key]) => query.labels.includes(key))
          .forEach(([key, value]) => {
            confidences.push(`${key}-${'from' in value ? value.from : 0}-${('to' in value && value.to >= 0) ? value.to : 1}`);
          });
        query.confidences = confidences.join(',');
      }
      const payload = {
        cid: this.currentCompany.cid,
        pid: this.currentProject.pid,
        query,
      };
      this.getDashboardImagesByFilters(payload)
        .then((res) => {
          const images = res.data;
          this.updateMatch(images.length > 0);
        });
    },
  },
};
</script>
