<template>
  <div>
    <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Label Name"
              outlined
              v-model="label"
              required
              :error-messages="errors.label"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Label Abbreviation"
              outlined
              v-model="abbreviation"
              required
              :error-messages="errors.abbreviation"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              label="Severity Level"
              outlined
              :items="['None', 'Low', 'Medium', 'High', 'Critical']"
              v-model="severity"
              :error-messages="errors.severity"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              label="Label to Replace"
              outlined
              :items="selectableLabels"
              v-model="replaceLabel"
              item-text="faultType"
              item-value="id"
              clearable
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Cancel</v-btn>
        <v-btn
          color="primary"
          text
          @click="submitForm"
          :loading="sendingRequestEmail"
        >Request</v-btn>
      </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RequestLabelForm',
  data: () => ({
    label: '',
    severity: '',
    abbreviation: '',
    replaceLabel: null,
    selectableLabels: [],
    errors: {
      label: '',
      abbreviation: '',
      severity: '',
    },
  }),
  methods: {
    clearErrors() {
      Object.keys(this.errors).forEach((key) => {
        this.errors[key] = '';
      });
    },
    clearForm() {
      this.label = '';
      this.severity = '';
      this.labelDescription = '';
      this.abbreviation = '';
      this.replaceLabel = null;
      this.selectableLabels = [];
    },
    closeDialog() {
      this.clearForm();
      this.clearErrors();
      this.$emit('closeDialog');
    },
    submitForm() {
      this.errors.label = (!this.label) ? 'Label is required' : '';
      this.errors.severity = (!this.severity) ? 'Severity is required' : '';
      this.errors.abbreviation = (!this.abbreviation) ? 'Abbreviation is required' : '';

      if (this.errors.label || this.errors.severity || this.errors.abbreviation) return;

      const payload = {
        client: this.currentCompany.companyName,
        clientId: this.currentCompany.cid,
        label: this.label,
        severity: this.severity,
        abbreviation: this.abbreviation,
        replaceLabel: this.replaceLabel,
        shouldReplaceLabel: false,
        replyEmail: this.currentUser.email,
        subject: `Label Change Request: ${this.currentCompany.companyName} (${process.env.VUE_APP_ENVIRONMENT})`,
        name: this.currentUser.name,
      };

      // Condition checks for null because
      // a label ID can be 0, which will be falsy
      if (this.replaceLabel !== null) {
        const { id, faultType } = this.flatProjectLabels.find((l) => l.id === this.replaceLabel);
        payload.shouldReplaceLabel = true;
        payload.replaceLabel = faultType;
        payload.replaceLabelId = id;
      } else payload.shouldReplaceLabel = false;
      this.$emit('request', payload);
    },
  },
  computed: {
    ...mapGetters(['flatProjectLabels', 'currentCompany', 'currentUser', 'sendingRequestEmail']),
  },
  mounted() {
    this.selectableLabels = [...this.flatProjectLabels];
  },
};
</script>
