<template>
  <v-container>
    <v-card
      class="pt-1"
      :loading="loadingImages || loadingFolders || loadingLabels"
      loading-height="6"
      height="100%"
    >
      <v-fade-transition>
        <DisplayImage
          class="pa-4"
          :image="currentImage"
          :imageType="type"
        />
      </v-fade-transition>
      <v-divider></v-divider>
      <!------------------- TOOLBAR FOR CAROUSEL ------------------->
      <v-card-text>
        <v-row align="center" justify="center" id="original-image-utils">
          <v-btn-toggle :value="toolbarActiveButtons">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs}">
                <v-btn
                  small
                  icon
                  id="select-all-btn"
                  @click="selectAll"
                  v-on="on"
                  v-bind="attrs"
                  :disabled="processingImage"
                >
                  <v-icon>select_all</v-icon>
                </v-btn>
              </template>
              <span>Select All</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  id="delete-image-btn"
                  :disabled="processingImage || disableDeleteProcess"
                  @click="confirmDelete"
                  v-on="on"
                  v-bind="attrs"
                  v-if="hasPermission('IMAGE_DELETE')"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Image</span>
            </v-tooltip>
            <!-- D O W N L O A D  I M A G E S -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  id="download-btn"
                  :disabled="processingImage || disableDeleteProcess"
                  @click="downloadSelectedImage"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>download</v-icon>
                </v-btn>
              </template>
              <span>Download Image</span>
            </v-tooltip>
            <!-- I M A G E  P R O C E S S I N G -->
            <v-dialog v-if="hasDuplicates" v-model="duplicateWarningDialog" width="500">
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      small
                      icon
                      :disabled="processingImage || disableDeleteProcess"
                      id='confirm-process-img-btn'
                      v-if="originalCarousel && hasPermission('AI_PROCESSING')"
                      v-on="{...tooltip, ...dialog}"
                      v-bind="attrs"
                    >
                      <v-icon>transform</v-icon>
                    </v-btn>
                  </template>
                  <span>Process Image</span>
                </v-tooltip>
              </template>
              <DuplicateImageProcessWarning
                v-on:closeWarning="closeWarningDialog"
                v-on:confirm="confirmProcessing"
              />
            </v-dialog>
            <v-tooltip bottom v-if="originalCarousel && !hasDuplicates">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  :class="(hasDuplicates) ? 'no-display' : ''"
                  :disabled="processingImage || disableDeleteProcess"
                  id='process-img-btn'
                  @click="confirmProcessing"
                  v-on="on"
                  v-bind="attrs"
                  v-if="hasPermission('AI_PROCESSING')"
                >
                  <v-icon>transform</v-icon>
                </v-btn>
              </template>
              <span>Process Image</span>
            </v-tooltip>
            <!-- C A N C E L  P R O C E S S I N G -->
            <v-dialog
              v-model="cancelProcessD"
              width="500"
            >
<!--              <template v-slot:activator="{ on: dialog, attrs }">-->
<!--                <v-tooltip bottom>-->
<!--                  <template v-slot:activator="{ on: tooltip }">-->
<!--                    <v-btn-->
<!--                      small-->
<!--                      icon-->
<!--                      :disabled="!processingImage"-->
<!--                      id='process-cancel-img-btn'-->
<!--                      v-if="originalCarousel"-->
<!--                      v-on="{...tooltip, ...dialog}"-->
<!--                      v-bind="attrs"-->
<!--                    >-->
<!--                      <v-icon>mdi-cancel</v-icon>-->
<!--                    </v-btn>-->
<!--                  </template>-->
<!--                  <span>Cancel Processing</span>-->
<!--                </v-tooltip>-->
<!--              </template>-->
              <CancelProcessingWarning
                v-on:closeCancel="closeCancel"
                v-on:confirmCancel="confirmCancel"
              />
            </v-dialog>
            <!-- E N L A R G E  I M A G E -->
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      small
                      icon
                      id="enlarge-img-btn"
                      ref="imgViewBtn"
                      v-bind="attrs"
                      v-on="{...tooltip, ...dialog}"
                      :disabled="processingImage || disableZoomer"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                  </template>
                  View Image
                </v-tooltip>
              </template>
              <ImageZoomer
                :images="images"
                :type="type"
                v-on:closeZoomer="onCloseDialog"
                v-on:annotate="onAnnotateImage"
              />
            </v-dialog>
            <!-- H U M A N  I N  T H E  L O O P -->
            <v-dialog
              v-model="annotateDialog"
              @click:outside="closeAnnotation"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              persistent
              id="hitl-dialog"
              content-class="human-in-the-loop-dialog"
            >
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      small
                      id="human-in-loop-btn"
                      icon
                      disabled
                      v-bind="attrs"
                      v-on="{...tooltip, ...dialog}"
                      :to="annotatorPath"
                      ref="annotateBtn"
                      v-if="hasPermission('IMAGE_DETAIL_WRITE')"
                    >
                      <v-icon>brush</v-icon>
                    </v-btn>
                  </template>
                  Annotate
                </v-tooltip>
              </template>
              <v-card>
                <router-view v-on:closeDialog="closeAnnotation"></router-view>
              </v-card>
            </v-dialog>
            <!-- E N D  O F  H U M A N  I N  T H E  L O O P -->
            <v-dialog v-model="changePole" v-if="companyHas('poleByFolders')" persistent>
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      small
                      icon
                      :disabled="!canTransfer"
                      id='pole-switch-btn'
                      v-if="!originalCarousel"
                      v-on="{...tooltip, ...dialog}"
                      v-bind="attrs"
                    >
                      <v-icon>mdi-folder-swap</v-icon>
                    </v-btn>
                  </template>
                  <span>Change Pole</span>
                </v-tooltip>
              </template>
              <PoleChangeManager
                v-on:cancel="closePoleTransfer"
                :show="changePole"
              />
            </v-dialog>
            <FullScreenButton
              :url="(type === 'original')
                ? currentImage.originalImageUrl
                : currentImage.processedImageUrl"
              buttonType="icon"
              size="small"
            />
          </v-btn-toggle>
        </v-row>
      </v-card-text>
      <!------------------ END OF TOOLBAR ------------------->
      <v-divider></v-divider>
      <ImagePicker :images="images" />
    </v-card>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete dialog</v-card-title>
        <v-card-text>Confirm you want to delete the selected image(s)?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">No</v-btn>
          <v-btn color="green darken-1" text @click="onDeleteImages">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImagePicker from '@components/images/ImagePicker.vue';
import ImageZoomer from '@components/images/ImageZoomer.vue';
import DisplayImage from '@components/images/DisplayImage.vue';
import DuplicateImageProcessWarning from '@components/images/DuplicateImageProcessWarning.vue';
import PoleChangeManager from '@components/images/PoleChangeManager.vue';
import CancelProcessingWarning from '@components/images/CancelProcessingWarning.vue';
import FullScreenButton from '@components/images/FullScreenButton.vue';
import { baseProcessUrl } from '@utils/endpoint';
import {
  processOriginalImages,
  downloadImage,
  downloadAsZip,
  batchProcess,
} from '@utils/images';
import services from '@services';

export default {
  name: 'CarouselController',
  props: ['images', 'type'],
  components: {
    ImagePicker,
    ImageZoomer,
    DisplayImage,
    PoleChangeManager,
    CancelProcessingWarning,
    DuplicateImageProcessWarning,
    FullScreenButton,
  },
  data: () => ({
    snackbar: false,
    snackcolor: 'info',
    snackmessage: '',
    dialog: false,
    duplicateWarningDialog: false,
    deleteDialog: false,
    cancelProcessD: false,
    annotateDialog: false,
    changePole: false,
    cancelTokens: [],
    startBuffer: false,
  }),
  methods: {
    ...mapActions([
      'setSelectedImages',
      'finishProcessingImages',
      'setNotification',
      'processImages',
      'deleteImages',
      'setCurrentImage',
      'deleteProcessedImages',
      'getProcessedImages',
      'addCancelToken',
      'clearCancelTokens',
      'applyCancelTokens',
      'startBatchProcessing',
      'setProcessingImages',
      'setNotification',
      'longPollProgress',
      'getImagesByProject',
    ]),
    // Functions to close dialogs
    closeWarningDialog() { this.duplicateWarningDialog = false; },
    closeCancel() { this.cancelProcessD = false; },
    confirmDelete() { this.deleteDialog = true; },
    onCloseDialog(close) { this.dialog = close; },
    closePoleTransfer() { this.changePole = false; },
    selectAll() {
      this.$store.dispatch('setSelectedImages',
        this.images.length === this.selectedImages.length ? [] : this.images);
    },
    confirmCancel() {
      this.cancelProcessD = false;
      this.finishProcessingImages(false);
      this.applyCancelTokens();
    },
    /*
      |-------------------------------------------------------------------|
      |   @method       onSuccess                                         |
      |   @params       res                                               |
      |   description   Method to execute whenever a delete image action  |
      |                 is complete                                       |
      |-------------------------------------------------------------------|
    */
    onSuccess(res) {
      const message = (this.type === 'original')
        ? `Successfully deleted ${res.data.length} images`
        : `Successfully deleted ${res.data.length} processed images`;
      const currentId = this.currentImage.id;
      if (res.data.includes(currentId)) {
        this.setCurrentImage(this.images[0]);
      }
      this.setNotification({
        success: true,
        message,
        color: '',
      });
    },
    /*
      |-------------------------------------------------------------------|
      |   @method       onError                                           |
      |   @params       res                                               |
      |   description   Method to execute whenever a delete image action  |
      |                 cannot be completed                               |
      |-------------------------------------------------------------------|
    */
    onError(err) {
      console.log(err);
      this.setNotification({
        success: true,
        message: err.response?.data.message,
        color: 'error',
      });
    },
    /*
      |-------------------------------------------------------------------|
      |   @method       deleteOriginalImages                              |
      |   @params       payload                                           |
      |   description   Deletes original images from a project            |
      |-------------------------------------------------------------------|
    */
    deleteOriginalImages(payload) {
      this.deleteImages(payload)
        .then(this.onSuccess)
        .catch(this.onError);
    },
    /*
      |-------------------------------------------------------------------|
      |   @method       deleteProcessed                                   |
      |   @params       payload                                           |
      |   description   Deletes processed images from a project           |
      |-------------------------------------------------------------------|
    */
    deleteProcessed(payload) {
      this.deleteProcessedImages(payload)
        .then((res) => {
          this.onSuccess(res);
        })
        .catch(this.onError);
    },
    deleteAllProcessed(payload) {
      this.deleteProcessedImages(payload)
        .then((res) => {
          this.onSuccess(res);
        })
        .catch(this.onError);
    },
    /*
      |-------------------------------------------------------------------|
      |   @method       onDeleteImages                                    |
      |   @params       void                                              |
      |   description   Decides whether or not we delete processed iamges |
      |                 or a normal image                                 |
      |-------------------------------------------------------------------|
    */
    async onDeleteImages() {
      this.deleteDialog = false;
      const deletingImages = this.selectedImageList.map((image) => ({
        ds_id: { filename: image.filename, date: image.date },
        id: image.id,
      }));

      const payload = {
        cid: this.currentCompany.firestore_cid,
        pid: this.currentProject.firestore_pid,
        company_id: this.currentCompany.cid,
        project_id: this.currentProject.pid,
        images: deletingImages,
      };

      if (this.type === 'original') return this.deleteOriginalImages(payload);
      if (this.selectedImageList.length === this.images.length) {
        return this.deleteAllProcessed(payload);
      }
      return this.deleteProcessed(payload);
    },
    async downloadSelectedImage() {
      this.setNotification({ success: true, message: `Zipping and downloading ${this.selectedImageList.length} selected images.` });
      if (this.type === 'ai') await this.downloadAnyImage('ai');
      else await this.downloadAnyImage();
      this.setSelectedImages([]);
    },
    async downloadAnyImage(type) {
      // For each image in selectedImageList, generate image blob
      const blobs = await Promise.all(
        this.selectedImageList.map((image) => new Promise((resolve, reject) => {
          fetch(type === 'ai' ? image.processedImageDownloadUrl : image.originalImageDownloadUrl)
            .then((response) => response.blob())
            .then((blob) => resolve({ image, blob }))
            .catch((err) => reject(err));
        })),
      );

      // If blob list is larger than lenght of 1
      // Download zip file
      if (blobs.length > 1) {
        const zipContent = await downloadAsZip(blobs, 'original');
        downloadImage(zipContent, 'buzz.zip');
      } else downloadImage(blobs[0].blob, blobs[0].image.filename);
    },
    async confirmProcessing() {
      // Close the confirm dialog
      this.closeWarningDialog();

      const response = await this.onBatchProcessImages();
      if (response.status === 202) {
        this.setProcessingImages(true);
        const task = response.data;

        const url = `${baseProcessUrl}/batch_process/result/${task.task_id}`;
        const res = await services.auth.getJwtToken(this.currentUser.firestore_uid);
        const { token } = res.data;

        const request = {
          type: 'GET',
          url,
          headers: { Authorization: token },
          taskId: task.task_id,
        };
        this.longPollProgress(request);
      }
      this.$store.dispatch('setSelectedImages', []);
    },
    async onBatchProcessImages() {
      this.clearCancelTokens();
      const { addCancelToken, startBatchProcessing } = this;

      const data = {
        images: this.selectedImageList,
        company: this.currentCompany,
        pid: this.currentProject.pid,
        uid: this.currentUser.uid,
      };

      if (this.currentCompany.ml_model) {
        data.models = [this.currentCompany.ml_model];
      }

      const actions = { addCancelToken, batchProcessImages: startBatchProcessing };

      return batchProcess(data, actions);
    },
    async onProcessImages() {
      this.clearCancelTokens();

      const data = {
        images: this.selectedImageList,
        company: this.currentCompany,
        pid: this.currentProject.pid,
        uid: this.currentUser.uid,
      };
      const actions = {
        addCancelToken: this.addCancelToken,
        processImages: this.processImages,
      };

      return processOriginalImages(data, actions);
    },
    closeAnnotation() {
      this.annotateDialog = false;
      if (this.fromEnlargeDialog) this.$refs.imgViewBtn.$el.click();
    },
    onAnnotateImage() {
      this.dialog = false;
      this.fromEnlargeDialog = true;
      this.$refs.annotateBtn.$el.click();
    },
  },
  watch: {
    $route(newRoute) {
      const { name } = newRoute;
      const isOutsideAnnotator = (name === 'OriginalImages' || name === 'ProcessedImages');
      if (isOutsideAnnotator && this.annotateDialog) this.closeAnnotation();
    },
  },
  computed: {
    ...mapGetters([
      'hasPermission',
      'companyHas',
      'allImages',
      'currentImage',
      'currentProject',
      'processingImage',
      'loadingImages',
      'currentCompany',
      'currentUser',
      'currentImageFilter',
      'selectedImageList',
      'duplicateImages',
      'currentUser',
      'loadingFolders',
      'loadingLabels',
      'currentFolder',
    ]),
    selectedImages() {
      return this.$store.state.images.selected;
    },
    originalCarousel() { return this.type === 'original'; },
    disableDeleteProcess() { return !this.selectedImageList.length; },
    disableZoomer() { return this.selectedImageList.length > 1; },
    hasDuplicates() {
      return !!this.selectedImageList
        .filter((image) => image.processedImageUrl).length;
    },
    folderSelected() {
      return this.currentFolder.path !== '__all__';
    },
    canTransfer() {
      const imagesSelected = this.selectedImageList.length > 0;
      return imagesSelected && this.folderSelected;
    },
    annotatorPath() {
      const { currentImage } = this;
      const { name } = this.$route;

      const { tab } = this.$route.params;

      const action = (name === 'HumanInTheLoop' || name === 'OriginalImages') ? 'annotate' : 'edit';
      const basePath = `/project/${this.currentProject.pid}/images/${tab}/${currentImage.filename}/${currentImage.date}`;
      const src = `${basePath}${(tab === 'original') ? '' : '/processed'}/${action}`;

      return src;
    },
    toolbarActiveButtons() {
      // activate all_button if all images selected
      return this.images.length === this.selectedImages.length ? 0 : null;
    },
  },
  mounted() {
    const { name } = this.$route;
    if (name === 'HumanInTheLoop' || name === 'HumanInTheLoopEdit') this.annotateDialog = true;
  },
  destroyed() {
    this.$store.dispatch('setSelectedImages', []);
  },
};
</script>

<style scoped>
  .no-display { display: none; }
</style>
