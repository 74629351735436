import services from '@services';

const state = {
  filters: {
    severities: [],
    labels: [],
    priorities: [],
    timeframes: [],
    folders: ['__all__'],
    range: {},
  },
  loading: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  loadingDashboard: () => state.loading,
  dashboardFilters: () => state.filters,
};

const actions = {
  setDashboardFilters({ commit }, filters) {
    commit('set_filters', filters);
  },
  clearDashboardFilters(store) {
    store.commit('set_filters', { type: 'labels', value: [] });
    store.commit('set_filters', { type: 'severities', value: [] });
    store.commit('set_filters', { type: 'timeframes', value: [] });
    store.commit('set_filters', { type: 'priorities', value: [] });

    let folderValue;
    if (store.getters.currentFolder.path === '__all__' || !store.getters.currentFolder.path) folderValue = ['__all__'];
    else folderValue = [store.getters.currentFolder.path];

    store.commit('set_filters', { type: 'folders', value: folderValue });
  },

  getDashboardImagesByFilters(store, payload) {
    const { cid, pid, query } = payload;
    store.commit('set_loading_images', true);

    query.isReport = true;
    return new Promise((resolve, reject) => {
      services.dashboard.filter(cid, pid, query)
        .then((res) => {
          if (res.data.length > 0) {
            store.commit('fetch_images', res.data);

            const [currentImage] = res.data;
            store.dispatch('setCurrentImage', currentImage);
          }
          store.commit('set_loading_images', false);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  getDashboardData(store, payload) {
    store.commit('loading_dashboard', true);

    return new Promise((resolve, reject) => {
      services.dashboard.get(payload.cid, payload.pid)
        .then((res) => {
          store.commit('fetch_project', res.data.project);
          store.commit('fetch_images', res.data.images);
          store.commit('set_folders', res.data.folders);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  loading_dashboard: (state, loading) => {
    state.loading = loading;
  },
  set_filters: (state, filters) => {
    state.filters[filters.type] = filters.value;
  },
  set_severity_filters: (state, filters) => {
    state.severityFilters = filters;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
