import severities from '@utils/severity-level';
/* eslint-disable consistent-return */

const createFillerOption = (text) => {
  const fillerOption = document.createElement('option');
  fillerOption.value = text;
  fillerOption.innerText = text;
  fillerOption.dataset.tag = text;

  return fillerOption;
};

const LabelSelectorWidget = (abbreviation) => (args) => {
  const labels = abbreviation.map((abbr) => abbr.faultType);
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling')
    : null;

  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;

  // 5. Triggers callbacks on user actions
  const addTag = (evt) => {
    const labelBody = { type: 'TextualBody', purpose: 'labeling', value: evt.target.value };

    if (currentLabelBody) {
      args.onUpdateBody(currentLabelBody, labelBody);
    } else {
      args.onAppendBody(labelBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'labelselector-widget';

  // select input creation
  const selectInput = document.createElement('select');
  selectInput.className = 'form-select';
  selectInput.setAttribute('aria-label', 'Select a label');

  selectInput.appendChild(createFillerOption('Please select a label'));

  // Setting each select options to be a label
  const options = labels.sort().map((label) => {
    const optionTag = document.createElement('option');
    if (label === currentLabelValue) optionTag.selected = true;
    optionTag.value = label;
    optionTag.innerText = label;
    optionTag.dataset.tag = label;
    return optionTag;
  });

  // Appending each option tags to selectInput
  options.forEach((option) => {
    selectInput.appendChild(option);
  });

  // Adding a change event listener to the select field
  selectInput.addEventListener('change', addTag);

  // Appending the select field to the container
  container.appendChild(selectInput);

  return container;
};

const CustomLabelTextWidget = (args) => {
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling')
    : null;
  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;
  // 5. Triggers callbacks on user actions
  const addTag = (evt) => {
    const labelBody = { type: 'TextualBody', purpose: 'labeling', value: evt.target.value };

    if (currentLabelBody) {
      args.onUpdateBody(currentLabelBody, labelBody);
    } else {
      args.onAppendBody(labelBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'labeltext-widget';

  // select input creation
  const textInput = document.createElement('textarea');
  textInput.className = 'annote-form-textbox';
  textInput.setAttribute(
    'aria-label',
    'Enter Custom comment or label, this will overide the one selected above.',
  );
  textInput.setAttribute(
    'placeholder',
    'Enter Custom comment or label, this will overide the one selected above.',
  );
  textInput.value = currentLabelValue;

  // Adding a change event listener to the select field
  textInput.addEventListener('change', addTag);

  // Appending the select field to the container
  container.appendChild(textInput);

  return container;
};

const LabelDisplayWidget = (args) => {
  // 1. Find the current label setting in the annotation, if any
  const currentLabelBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'labeling')
    : null;
  const isAI = args.annotation ? args.annotation.underlying.from_model : false;
  // 4. Keep value in a variable
  const currentLabelValue = currentLabelBody ? currentLabelBody.value : null;
  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'labeldisplay-widget';

  // select input creation
  const textInput = document.createElement('textarea');
  textInput.readOnly = true;
  textInput.className = 'label-display-textbox';
  textInput.value = currentLabelValue;
  const description = document.createElement('div');

  description.innerText = `${(isAI ? 'AI' : 'HITL')}  -   ${args.annotation?.underlying?.confidence}`;

  const modelIdExists = 'model_id' in args.annotation?.underlying;
  const modelNameExists = 'model_name' in args.annotation?.underlying;

  const modelId = document.createElement('div');
  modelId.innerText = `Model ID - ${(modelIdExists) ? args.annotation?.underlying?.model_id : 'N/A'}`;

  const modelName = document.createElement('div');
  modelName.innerText = `Model Name - ${(modelNameExists) ? args.annotation?.underlying?.model_name : 'N/A'}`;

  const divider = document.createElement('hr');
  divider.style.borderTop = '1px solid #000';

  // Appending the select field to the container
  container.appendChild(textInput);
  container.appendChild(description);
  container.appendChild(divider);
  container.appendChild(modelId);
  container.appendChild(modelName);

  return container;
};

const SeveritySelectorWidget = (args) => {
  // 2. Find the current severity setting in the annotation, if any
  const currentSeverityBody = args.annotation
    ? args.annotation.bodies.find((b) => b.purpose === 'severity')
    : null;
  // 4. Keep value in a variable
  const currentSeverityValue = currentSeverityBody ? currentSeverityBody.value : null;
  // 5. Triggers callbacks on user actions
  const addTag = (evt) => {
    const severityBody = { type: 'TextualBody', purpose: 'severity', value: evt.target.value };

    if (currentSeverityBody) {
      args.onUpdateBody(currentSeverityBody, severityBody);
    } else {
      args.onAppendBody(severityBody);
    }
  };

  // 4. Renders the UI elements
  // The container that holds the widget components
  const container = document.createElement('div');
  container.className = 'severityselector-widget';

  // select input creation
  const selectInput = document.createElement('select');
  selectInput.className = 'form-select';
  selectInput.setAttribute('aria-label', 'Please Select a severity level');

  selectInput.appendChild(createFillerOption('Please Select a severity level'));

  // Setting each select options to be a label
  const options = severities.map((severity) => {
    const optionTag = document.createElement('option');
    if (severity === currentSeverityValue) optionTag.selected = true;
    optionTag.value = severity;
    optionTag.innerText = severity;
    optionTag.dataset.tag = severity;
    return optionTag;
  });

  // Appending each option tags to selectInput
  options.forEach((option) => {
    selectInput.appendChild(option);
  });

  // Adding a change event listener to the select field
  selectInput.addEventListener('change', addTag);

  // Appending the select field to the container
  container.appendChild(selectInput);

  return container;
};

const LabelFormatter = (annotation) => {
  const labelBody = annotation.bodies.find((b) => b.purpose === 'label');

  if (labelBody) return labelBody.value;
};

export default {
  labelDisplay: LabelDisplayWidget,
  labeling: LabelSelectorWidget,
  customLabeling: CustomLabelTextWidget,
  severity: SeveritySelectorWidget,
  formatter: LabelFormatter,
};
