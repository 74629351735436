<template>
  <v-dialog v-model="openDialog" max-width="600px">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            :disabled="selectedImages.length < 1"
            color="primary"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </template>
        GSUtil Download Command
      </v-tooltip>
    </template>
    <!-- <GSUtilCommand v-if="openDialog"/> -->
    <v-card v-if="openDialog">
      <v-card-title class="headline">
        GSUtil Download Command
      </v-card-title>
      <v-card-text>
        <ClipboardCopy
          :copyText="generateGsutilCommand(bucket, selectedImages)"
          label="GSUtil Download Command"
          :isTextField="false"
          @success="onCopySuccess"
          @error="onCopyError"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="() => this.openDialog = false"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import ClipboardCopy from '@components/common/ClipboardCopy.vue';

export default {
  name: 'GSUtilDownload',
  components: {
    ClipboardCopy,
  },
  props: {
    bucket: {
      type: String,
      default: '',
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      openDialog: false,
    };
  },
  methods: {
    ...mapActions(['setNotification']),
    generateGsutilCommand: (bucket, paths) => {
      const allPaths = paths.map((path) => `  "gs://${bucket}/${path}" \\\n`).join('');
      return `gsutil -m cp  \\\n${allPaths}.`;
    },
    onCopySuccess() {
      this.setNotification({
        message: 'Copied to clipboard',
        success: true,
        color: '',
      });
    },
    onCopyError() {
      this.setNotification({
        message: 'Failed to copy to clipboard',
        success: true,
        color: 'error',
      });
    },
  },
};
</script>
