import helpers from '@methods/helpers';
import EXIF from 'exif-js';
import moment from 'moment/moment';
import platformBackendClient from '@backend-clients/platform-backend';

export default {
  get: (payload) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/image/${payload.filename}/project/${payload.pid}/company/${payload.cid}/${payload.date}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  get_batch: (cid, pid, ids) => platformBackendClient.post(`/api/image/batch/?company_id=${cid}&project_id=${pid}`, ids),

  getByCompany: (cid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/image/${cid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getByProject: (cid, pid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/image/company/${cid}/project/${pid}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  }),
  getByFolder: (cid, pid, folder) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/image/company/${cid}/project/${pid}/folder/${folder}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  create: (cid, pid, data) => platformBackendClient.post(`/api/image/?company_id=${cid}&project_id=${pid}`, data),

  update: (cid, pid, iid, image) => new Promise((resolve, reject) => {
    platformBackendClient.put(`/api/image/company/${cid}/project/${pid}/${iid}`, image)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  delete: (companyId, projectId, cid, pid, images) => new Promise((resolve, reject) => {
    platformBackendClient.delete(`/api/image/companies/${companyId}/projects/${projectId}/?cid=${cid}&pid=${pid}`, { data: images })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),

  exif: (file) => new Promise((resolve, reject) => {
    if (file === undefined) reject();
    if (file !== undefined) {
      EXIF.getData(file, () => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const tags = EXIF.getAllTags(file);

          const lat = EXIF.getTag(file, 'GPSLatitude');
          const latRef = EXIF.getTag(file, 'GPSLatitudeRef');
          const long = EXIF.getTag(file, 'GPSLongitude');
          const longRef = EXIF.getTag(file, 'GPSLongitudeRef');
          const timestamp = (tags.DateTime) ? moment(tags.DateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD_HHmmss') : null;

          const image = {
            filename: file.name,
            hash: file.renamedFilename,
            location: [null, null],
            date: moment(),
            timestamp,
            isDeleted: false,
          };

          if (
            lat !== undefined
            || latRef !== undefined
            || long !== undefined
            || longRef !== undefined
          ) {
            image.location[0] = helpers.convertDMSToDD(lat[0], lat[1], lat[2], latRef);
            image.location[1] = helpers.convertDMSToDD(long[0], long[1], long[2], longRef);
          }

          resolve(image);
        };
        fileReader.readAsDataURL(file);
      });
    }
  }),

  // TODO: Find a way to refactor this endpoint or see if we even need it
  getByProjects: (payload) => new Promise((resolve, reject) => {
    platformBackendClient.post(`/api/image/images_from_projects/${payload.cid}`, payload.projects)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getByProjectAndFolder: (cid, pid, folderPath) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/image/company/${cid}/project/${pid}/folder/${folderPath}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getProcessedByProject: (payload) => new Promise((resolve, reject) => {
    platformBackendClient.post(`/api/image/project/company/${payload.cid}/processed`, payload.projects)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  updateAiFeedback: (query, feedback) => new Promise((resolve, reject) => {
    platformBackendClient.put('/api/image/feedback', { ai_feedback: feedback }, { params: query })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  updateImageNote: (cid, pid, iid, type, update) => platformBackendClient.put(`/api/image/company/${cid}/project/${pid}/${iid}/note/${type}`, update),
  updateImageAnnotation: (companyId, projectId, id, update) => platformBackendClient.put(`/api/image/company/${companyId}/project/${projectId}/${id}/annotation`, update),
};
