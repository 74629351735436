const convertProcessDetails = (processDetails) => {
  const processedDetailRegions = processDetails.prediction.map((p) => p.region);
  const regions = {};

  processedDetailRegions.forEach((processedDetailRegion, index) => {
    regions[`${index}`] = processedDetailRegion;
  });

  const processedDetail = {
    notes: processDetails.notes,
    labels: processDetails.prediction,
    regions,
    severity: processDetails.severity,
    confidence: processDetails.prediction.map((p) => p.confidence),
    caption: processDetails.caption,
  };

  return processedDetail;
};

export default convertProcessDetails;
