<template>
  <v-card>
    <v-card-text>
      <v-container fluid>
        <v-row v-if="loadingImages">
          <v-col cols="6">
            <SkeletonLoader type="card"/>
          </v-col>
          <v-col cols="6">
            <SkeletonLoader type="card" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="10">
            <ImageHashSearch
              @search="getSearchValue"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              text
              color="primary"
              @click="searchImage"
              icon
            >
            <v-icon>search</v-icon>
          </v-btn>
          </v-col>
          <v-col cols="6">
            <AnnotoriousAnalyticsRenderer
              :images="currentImage"
              :currentTopImage="currentImage"
              :buzzMode="true"
            />
            {{ (currentImage.process_details)
              ? 'AI Processed Image'
              : 'Has not been humanly modified' }}
          </v-col>
          <v-col cols="6">
            <AnnotoriousAnalyticsRenderer
              :images="currentImage"
              :currentTopImage="currentImage"
            />
            {{ (currentImage.process_details)
              ? 'HITL Annotated Image'
              : 'AI processed'
            }}
          </v-col>
          <v-col cols="6">
            <v-checkbox
              :label="`Select ${currentImage.filename}`"
              v-model="selectedImages[currentImage.id]"
              :key="currentImage.id"
              @change="onImageToggled(currentImage.id)"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <ClipboardCopy
              :copyText="currentImage.hash"
              :label="`Image hash for ${currentImage.filename}`"
              @success="onCopySuccess"
              @error="onCopyError"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <ImageNavigationButtons :images="images" />
      <v-spacer></v-spacer>
      <GSUtilDownload
        :selectedImages="selectedImagePaths"
        :bucket="currentCompany.bucket"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageNavigationButtons from '@components/dashboard/navigations/ImageNavigationButtons.vue';
import AnnotoriousAnalyticsRenderer from '@components/annotations/AnnotoriousAnalyticsRendere.vue';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';
import ClipboardCopy from '@components/common/ClipboardCopy.vue';
import GSUtilDownload from '@components/dashboard/buzz-mode/GSUtilDownload.vue';
import ImageHashSearch from '@components/dashboard/buzz-mode/ImageHashSearch.vue';
import services from '@services';

export default {
  name: 'DashboardImage',
  props: ['images'],
  components: {
    ImageNavigationButtons,
    AnnotoriousAnalyticsRenderer,
    SkeletonLoader,
    ClipboardCopy,
    GSUtilDownload,
    ImageHashSearch,
  },
  data: () => ({
    annotateDialog: false,
    selectedImages: {},
    selectedImagePaths: [],
    hashSearch: '',
  }),
  methods: {
    onImageToggled(id) {
      const currentImageSelected = this.selectedImages[id];
      const image = this.images.find((img) => img.id === id);
      const { originalImageUrl } = image;
      const { searchParams } = new URL(originalImageUrl);
      const path = searchParams.get('image_path');

      if (currentImageSelected) {
        this.selectedImagePaths.push(path);
      } else {
        this.selectedImagePaths = this.selectedImagePaths.filter(
          (imagePath) => imagePath !== path,
        );
      }
    },
    closeAnnotation() {
      this.annotateDialog = false;
      if (this.fromEnlargeDialog) {
        this.$refs.imgViewBtn.$el.click();
      }
    },
    onCopySuccess() {
      this.$store.dispatch('setNotification', {
        success: true,
        message: `Image hash for ${this.currentImage.filename} copied to clipboard`,
        color: 'success',
      });
    },
    onCopyError() {
      this.$store.dispatch('setNotification', {
        success: true,
        message: 'There was an error copying image hash to clipboard',
        color: 'error',
      });
    },
    getSearchValue(searchValue) {
      this.hashSearch = searchValue;
    },
    searchImage() {
      services
        .processed_images
        .getByImageHash(this.currentCompany.cid, this.currentProject.pid, this.hashSearch)
        .then((res) => {
          const image = { ...res.data };
          this.$emit('changeImage', image);
        });
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'hasPermission', 'loadingImages', 'currentCompany', 'currentProject']),
    url() {
      return (this.currentImage.compressedUrl)
        ? this.currentImage.compressedUrl
        : this.currentImage.originalImageUrl;
    },
  },
  mounted() {
    this.images.forEach((image) => {
      this.selectedImages[image.id] = false;
    });
  },
};
</script>
